import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout/Layout';
import TituloCategorias from '../components/TituloCategorias';
import TarjetaPoema from '../components/Cards/TarjetaPoema';
/**
 * Página con la lista de categorías.
 */
const PoesiaPage = () => {
    // Consulta
    const data = useStaticQuery(queryCategorias);
    // const categorias = data.allMdx.distinct;
    const detalleCategorias = data.allCategoriasJson.nodes;

    // Renderización
    return (
        <Layout>
            <TituloCategorias>Poemas por categorías</TituloCategorias>
            <MarcoCategorias>
                {detalleCategorias.map((category) => {
                    return (<TarjetaPoema
                        enlace={`/${category.categoria}`}
                        titulo={category.nombre_completo}
                        fecha="" /> );
                })}
            </MarcoCategorias>
        </Layout>
    );
}

// Consulta para obtener la lista de categorías
const queryCategorias = graphql`
query {
    allMdx {
        distinct(field: frontmatter___category)
    },
    allCategoriasJson {
        nodes {
            categoria
            nombre_completo
        }
    }
}`;

// Componente de estilo para ajustar la lista de categorías
const MarcoCategorias = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
`;

export default PoesiaPage;