import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

/**
 * Componente para una tarjeta de enlace al poema.
 * 
 * Es un pequeño recuadro con el título de la poesía, su
 * fecha y una pequeña imagen, que luego enlaza con la página
 * completa de la poesía.
 */
const TarjetaPoema = (props) => {
    return (
        <Tarjeta>
            <Link to={props.enlace} style={{ display: "block" }}>
                <div className="Estructura">
                    <Titulo>{props.titulo}</Titulo>
                    <Fecha><span>{props.fecha}</span></Fecha>
                </div>
            </Link>
        </Tarjeta>
    );
}

// Componente de estilo de la tarjeta entera y a la estructura de
// los elementos de dentro (el título y la fecha por ahora).
const Tarjeta = styled.div`
    margin: 0.5rem;
    width: 48%;
    /*flex: 1 0 48%;*/

    a {
        display: block;
        text-decoration: none;
    }

    a:visited {
        color: var(--clr-black);
    }

    :hover {
        cursor: pointer;
    }

    .Estructura {
        display: table;
        width: 320px;
        padding: 4px 8px;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);

        :hover {
            background-color: var(--clr-primary-10);
        }
    }
`;

// Componente de estilo para el título
const Titulo = styled.div`
    display: table-cell;
    font-size: 16px;
    font-style: italic;
    color: var(--clr-grey-1);
`;

// Componente de estilo para el título
const Fecha = styled.div`
    display: table-cell;
    font-size: 12px;
    text-align: right;
    margin-left: auto;
`;

export default TarjetaPoema;