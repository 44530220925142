import styled from 'styled-components';

/**
 * Componente para darle estilo al título de las categorías.
 */
const TituloCategoria = styled.div`
    width: 80%;
    margin: 2rem auto;
    padding: 8px 0px;
    font-family: var(--ff-primary);
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: var(--clr-principal);

`;

export default TituloCategoria;